export default function Loading() {
  return (
    <div className="mt-32 flex w-full justify-center">
      <Spinner />
    </div>
  )
}

// ref https://zenn.dev/catnose99/articles/19a05103ab9ec7
export function Spinner() {
  return (
    <div className="h-10 w-10 animate-spin rounded-full border-4 border-primary border-t-transparent"></div>
  )
}
